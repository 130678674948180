export const productTypes = [
  { productTypeId: '001', name: 'Access Control Card' },
  { productTypeId: '002', name: 'Access Control Keypad' },
  { productTypeId: '003', name: 'Access Control Reader' },
  { productTypeId: '004', name: 'Accessory' },
  { productTypeId: '005', name: 'Alarm' },
  { productTypeId: '006', name: 'Alarm Panel' },
  { productTypeId: '007', name: 'Alarm Sensor' },
  { productTypeId: '008', name: 'Bracket' },
  { productTypeId: '009', name: 'Cable' },
  { productTypeId: '010', name: 'DVR' },
  { productTypeId: '011', name: 'Hard Drive' },
  { productTypeId: '012', name: 'IP Camera' },
  { productTypeId: '013', name: 'Intercom Door Station' },
  { productTypeId: '014', name: 'Intercom Monitor' },
  { productTypeId: '015', name: 'KVM Switch' },
  { productTypeId: '016', name: 'Lens' },
  { productTypeId: '017', name: 'Lock' },
  { productTypeId: '018', name: 'Miscellaneous' },
  { productTypeId: '019', name: 'Monitor' },
  { productTypeId: '020', name: 'NVR' },
  { productTypeId: '021', name: 'Power Supply' },
  { productTypeId: '022', name: 'Rack' },
  { productTypeId: '023', name: 'Router' },
  { productTypeId: '024', name: 'Server' },
  { productTypeId: '025', name: 'Software' },
  { productTypeId: '026', name: 'Switch' },
  { productTypeId: '027', name: 'TVI Camera' },
  { productTypeId: '028', name: 'UPS' },
  { productTypeId: '029', name: 'Wireless Transmission' },
  { productTypeId: '030', name: 'Workstation' }
];
