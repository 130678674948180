import React, { useState } from 'react';

import EmailLogin from './EmailLogin';
import ForgotPassword from './ForgotPassword';

const EmailMethod = () => {
  const [email, setEmail] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);

  if (forgotPassword) {
    return <ForgotPassword email={email} setEmail={setEmail} setForgotPassword={setForgotPassword} />;
  }
  return <EmailLogin setEmail={setEmail} email={email} setForgotPassword={setForgotPassword} />;
};

export default EmailMethod;
