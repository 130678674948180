import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { StyledIcon } from './styled-components';

const DashboardCard = (props) => {
  const [raised, setRaised] = useState(false);
  const { page } = props;
  return (
    <Link
      to={{ pathname: page.link }}
      target={page.link.startsWith('http') ? '_blank' : undefined}
      style={{ textDecoration: 'none' }}
    >
      <Card
        style={{ cursor: 'pointer', height: 200 }}
        raised={raised}
        onMouseEnter={() => setRaised(true)}
        onMouseLeave={() => setRaised(false)}
      >
        <CardHeader
          title={
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <StyledIcon>
                  <page.icon />
                </StyledIcon>
              </Grid>
              <Grid item>{page.name}</Grid>
            </Grid>
          }
        />
        <CardContent>
          <Typography>{page.description}</Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default DashboardCard;
