export const CREATE = 'CREATE';
export const READ = 'READ';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const READ_PAGE = 'READ_PAGE';
export const READ_POST = 'READ_POST';
export const READ_PROMOTION = 'READ_PROMOTION';
export const READ_PRODUCT_REQUEST = 'READ_PRODUCT_REQUEST';
export const READ_LEAVE_REQUEST = 'READ_LEAVE_REQUEST';
export const READ_EXPENSE_CLAIM = 'READ_EXPENSE_CLAIM';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_TOUCHED = 'SET_AUTH_TOUCHED';
export const LOGOUT = 'LOGOUT';
export const SET_USERS = 'SET_USERS';
export const SET_ACTIVE_USERS = 'SET_ACTIVE_USERS';
export const SET_USERS_TOUCHED = 'SET_USERS_TOUCHED';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_NOTIFICACTIONS = 'SET_NOTIFICACTIONS';
export const SET_NOTIFICACTIONS_TOUCHED = 'SET_NOTIFICACTIONS_TOUCHED';
export const START_UPLOAD = 'START_UPLOAD';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const FINISH_UPLOAD = 'FINISH_UPLOAD';
export const SET_POSTS_DATA = 'SET_POSTS_DATA';
export const SET_USERS_DATA = 'SET_USERS_DATA';
export const SET_ACTIVE_USERS_DATA = 'SET_ACTIVE_USERS_DATA';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_VENDORS = 'SET_VENDORS';
export const SET_SERVER_BUILD = 'SET_SERVER_BUILD';
