import * as yup from 'yup';

import { Button, Card, CardActions, CardContent, CircularProgress, Grid, TextField } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import { sendPasswordResetLink } from '../../../../store/actions/auth-user';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

const validationSchema = yup.object().shape({
  email: yup.string().label('Email').trim().required().email().max(100),
});

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const { email, setEmail, setForgotPassword } = props;
  const [loading, setLoading] = useState(false);
  const [validatedOnMount, setValidatedOnMount] = useState(false);

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      await dispatch(sendPasswordResetLink(values.email));
      setForgotPassword(false);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { email },
    onSubmit: submitHandler,
    validationSchema,
  });

  const {
    validateForm,
    values: { email: formEmail },
  } = formik;

  useEffect(() => {
    setEmail(formEmail);
  }, [formEmail, setEmail]);

  useEffect(() => {
    validateForm();
    setValidatedOnMount(true);
  }, [validateForm]);

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Card>
          <form onSubmit={formik.handleSubmit}>
            <Fragment>
              <CardContent>
                <Grid container justify='center'>
                  {props.loading ? (
                    <CircularProgress />
                  ) : (
                    <TextField
                      margin='normal'
                      fullWidth
                      label='Email Address'
                      type='email'
                      value={formik.values.email}
                      onChange={formik.handleChange('email')}
                      onBlur={formik.handleBlur('email')}
                      autoFocus={true}
                    />
                  )}
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container justify='flex-end'></Grid>
              </CardActions>
              <CardActions>
                <Grid container justify='flex-end' spacing={1}>
                  <Grid item>
                    <Button
                      onClick={() => setForgotPassword(false)}
                      type='button'
                      variant='outlined'
                      color='primary'
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={!formik.isValid || loading || !validatedOnMount}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Fragment>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};
export default ForgotPassword;
