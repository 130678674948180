import { Card, Grid, Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { confirmVerificationCode, getPhoneNumber, signInWithPhoneNumber } from '../../../store/actions/auth-user';

import CodeForm from './CodeForm';
import EmailForm from './EmailForm';
import { useDispatch } from 'react-redux';

const stepLabels = ['Enter email', 'Enter SMS code', 'Sign in'];

const PhoneMethod = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState();
  const [activeStep, setActiveStep] = useState(0);

  // Reset values upon mounting component
  useEffect(() => {
    if (activeStep === 0) {
      setEmail('');
    } else if (activeStep === 1) {
      setVerificationCode('');
    }
  }, [activeStep]);

  // Side effect of email value confirmed
  useEffect(() => {
    const asyncFunction = async () => {
      setLoading(true);
      const phoneNumber = await dispatch(getPhoneNumber(email));
      if (phoneNumber) {
        const appVerifier = window.recaptchaVerifier;
        const newConfirmationResult = await dispatch(signInWithPhoneNumber(phoneNumber, appVerifier));
        if (newConfirmationResult) {
          setConfirmationResult(newConfirmationResult);
          setActiveStep(1);
        }
      }
      setLoading(false);
    };
    if (email) {
      asyncFunction();
    }
  }, [email, dispatch]);

  // Side effect of verification code confirmed
  useEffect(() => {
    const asyncFunction = async () => {
      setLoading(true);
      setActiveStep(2);
      const result = await dispatch(confirmVerificationCode(confirmationResult, verificationCode));
      if (!result) {
        setActiveStep(1);
        setLoading(false);
      }
    };
    if (confirmationResult && verificationCode) {
      asyncFunction();
    }
  }, [confirmationResult, verificationCode, dispatch]);

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Card>
          {activeStep === 0 ? (
            <EmailForm loading={loading} setEmail={setEmail} />
          ) : (
            <CodeForm loading={loading} setActiveStep={setActiveStep} setVerificationCode={setVerificationCode} />
          )}
        </Card>
      </Grid>
      <Grid item>
        <Stepper style={{ backgroundColor: 'inherit' }} activeStep={activeStep} alternativeLabel={true}>
          {stepLabels.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default PhoneMethod;
