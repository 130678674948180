import { Container, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import EmailMethod from './EmailMethod';
import PhoneMethod from './PhoneMethod';
import firebase from '../../utils/firebase';

const signInMethods = {
  phone: {
    id: 'phone',
    label: 'SMS code',
  },
  email: {
    id: 'email',
    label: 'Email and password',
  },
};

const Login = () => {
  const captchaRef = useRef();
  const [signInMethod, setSignInMethod] = useState(signInMethods.phone.id);

  //Initialize the recaptcha div
  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(captchaRef.current, {
      size: 'invisible',
      callback: (_repsonse) => null,
    });
  }, []);

  const onSelectSignInMethod = (event) => {
    setSignInMethod(event.target.value);
  };

  return (
    <Fragment>
      <div ref={captchaRef} />
      <Container maxWidth='sm'>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <FormControl style={{ minWidth: 200, float: 'right' }}>
              <InputLabel>Sign in method</InputLabel>
              <Select value={signInMethod} onChange={onSelectSignInMethod}>
                {Object.values(signInMethods).map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            {signInMethod === signInMethods.phone.id && <PhoneMethod />}
            {signInMethod === signInMethods.email.id && <EmailMethod />}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};
export default Login;
